import React, { FC } from 'react';
import Row from 'react-bootstrap/Row';
import useScreenRecognition from 'hooks/useScreenRecognition';

import Image from 'common/Image';

import { BannerImageProps } from './models.d';

const ImageRender = ({ image, alt }) =>
  image?.imageCropper ? (
    <div>
      <Image imageData={image.imageCropper} alt={alt} loading="eager" />
    </div>
  ) : image?.imagePicker ? (
    <div>
      <Image imageData={image.imagePicker} alt={alt} objectFit="scale-down" />
    </div>
  ) : null;

const BannerImage: FC<BannerImageProps> = ({ desktopImage, mobileImage, alt }) => {
  const { isMobile } = useScreenRecognition();

  return (
    <Row data-testid="banner-image">
      {!isMobile ? <ImageRender image={desktopImage} alt={alt} /> : null}
      {isMobile && mobileImage ? <ImageRender image={mobileImage} alt={alt} /> : null}
    </Row>
  );
};

export default BannerImage;
