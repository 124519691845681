import React, { FC } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';

import ShoppingOptionsCard from 'components/ShoppingOptions/ShoppingOptionsCard';

import { DropdownCardProps } from './models';

import './DropdownCard.scss';

const DropdownCard: FC<DropdownCardProps> = ({ dropdownTitle, card }) => (
  <Dropdown data-testid="dropdown-card">
    <Dropdown.Toggle variant="success">
      <span role="button" tabIndex={0} className="button button__discover">
        {dropdownTitle}
      </span>
    </Dropdown.Toggle>

    <Dropdown.Menu data-testid="shopping-card">
      <ShoppingOptionsCard {...card} />
    </Dropdown.Menu>
  </Dropdown>
);

export default DropdownCard;
