import React, { FC } from 'react';
import { useId } from 'react-id-generator';
import { graphql } from 'gatsby';

import Layout from 'layout/Layout';
import { VisualType, VisualVariant } from 'common/Button';
import BannerImage from 'components/BannerImage';
import DropdownCard from 'components/DropdownCard';
import LinkButton from 'components/LinkButton';
import { bodyAdapter } from 'utils/bodyAdapter';
import BodyRenderer from 'utils/bodyRenderer';
import createBreadcrumbs from 'utils/createBreadcrumbs';

import { LandingPageProps } from './models';

import './LandingPage.scss';

const LandingPage: FC<LandingPageProps> = ({
  data: {
    landingPage: { seo, langProps, body, bannerImage, shoppingCard, ctaLinks, dropdownTitle },
    allSitePage: { nodes },
  },
  pageContext: {
    breadcrumb: { crumbs },
    areBreadcrumbsDisplayed,
    pagePathname,
  },
  location: { pathname },
}) => {
  const breadcrumbs = createBreadcrumbs(crumbs, areBreadcrumbsDisplayed, nodes, pathname);
  const adaptedBody = bodyAdapter(body);

  const { desktopBannerImage, mobileBannerImage, alt } = bannerImage[0];

  return (
    <Layout className="landing" {...{ seo, langProps, breadcrumbs, pagePathname }}>
      {desktopBannerImage ? (
        <BannerImage mobileImage={mobileBannerImage} desktopImage={desktopBannerImage} alt={alt} />
      ) : null}
      <div className="landing__cta-links">
        {ctaLinks?.length
          ? ctaLinks.map(({ text, ariaLabel, url }) => (
              <LinkButton
                key={text}
                text={text}
                type={VisualType.Discover}
                url={url[0].url}
                ariaLabel={ariaLabel}
                variant={VisualVariant.Dark}
              />
            ))
          : null}
        {dropdownTitle && shoppingCard?.length ? (
          <DropdownCard dropdownTitle={dropdownTitle} card={shoppingCard[0]} />
        ) : null}
      </div>

      {adaptedBody.map((component) => {
        const key = useId();

        return <BodyRenderer {...component} key={key} />;
      })}
    </Layout>
  );
};

export const query = graphql`
  query($link: String) {
    landingPage(link: { eq: $link }) {
      seo {
        ...SEOStructureFragment
      }
      langProps {
        urls {
          ...languageUrls
        }
        lang
      }
      bannerImage {
        desktopBannerImage {
          ...BannerImageFragment
        }
        mobileBannerImage {
          ...BannerImageFragment
        }
        alt
      }
      shoppingCard {
        ...ShoppingCardFragment
      }
      ctaLinks {
        ariaLabel
        text
        url {
          url
          name
        }
      }
      dropdownTitle
      body {
        newsletterNodePicker {
          ...NewsletterBannerFragment
        }
      }
    }
    allSitePage {
      ...FragmentAllPageSite
    }
  }
`;

export default LandingPage;
